/* eslint-disable import/first */
// THINGS THAT MUST/SHOULD START BEFORE THE APP STARTS
// print to log for debugging purposes in fullstory etc.
console.log(
  process.env.HASURA_GRAPHQL_ADMIN_SECRET,
  process.env.REACT_APP_GIT_HASH,
  process.env.REACT_APP_GIT_BRANCH
);

import 'symbol-observable';

import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import initializeDatadogContext from './util/initialize-datadog-context';
import { createHermesSingletons } from './createHermesSingletons';
import * as serviceWorker from './serviceWorker';
import {
  ReportUnhandledPromiseRejection,
  ReportReactError,
} from '@cresta/react-app';

import '@fontsource/inter';

const App = lazy(() => import('./App'));

initializeDatadogContext();
createHermesSingletons();

/**
 * ReportReactError can not catch error thrown in the file that it is instantiated,
 * we must put it above <App /> in order to catch all App errors
 */
ReactDOM.render(
  <ReportUnhandledPromiseRejection>
    <ReportReactError tag="app">
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
    </ReportReactError>
  </ReportUnhandledPromiseRejection>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
