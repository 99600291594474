import {
  AppType,
  createCustomerSingleton,
  createEventSingletons,
  CrestaEventBrokerConfig,
  getDomainAttributes,
  singletonRegistry,
} from '@cresta/react-app';
import { event } from '@cresta/client-event-bus';
import { HermesCustomerConfig } from '@cresta/config';

declare global {
  interface CrestaEventPayloadMap {}
}

// eslint-disable-next-line no-undef
const crestaEventBrokerConfig: CrestaEventBrokerConfig<CrestaEventPayloadMap> =
  {
    crestaToPlatform: [
      event.KNOWLEDGE_BASE_SAVED_RESPONSE_SELECT,
      event.GUIDED_WORKFLOW_SAVED_RESPONSE_SELECT,
      event.SUGGESTION_SAVED_RESPONSE_SELECT,
    ],
    platformToCresta: [],
  };

/**
 * singletons can be created one at a time or in batch (like below).
 */
const createHermesSingletons = () => {
  // call createAuthSingletons() to create auth related singletons
  const { gatewayOrigin } = getDomainAttributes(
    window.location.hostname,
    AppType.CHAT
  );
  createCustomerSingleton<HermesCustomerConfig>(
    singletonRegistry,
    `${gatewayOrigin}/common_api/v1/config/hermes_customer_config`
  );
  createEventSingletons(singletonRegistry, crestaEventBrokerConfig);
};

export { createHermesSingletons };
