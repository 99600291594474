import { datadogLogs, HandlerType } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { getVisitorId } from '@cresta/react-app';
import { getCustomerAccountName } from '@cresta/common-hermes';

interface NavigatorExperimental extends Navigator {
  connection: {
    effectiveType?: string;
    downlink?: number;
  } & NetworkInformation;
  deviceMemory?: number;
  hardwareConcurrency: number;
}

export default function initializeDatadogContext() {
  datadogRum.init({
    applicationId: process.env.REACT_APP_RUM_APPLICATION_ID || '',
    clientToken: process.env.REACT_APP_RUM_CLIENT_TOKEN || '',
  });
  datadogRum.addRumGlobalContext(
    'Client',
    getCustomerAccountName(window.location.host) || 'dev'
  );

  datadogLogs.init({
    clientToken: process.env.REACT_APP_RUM_CLIENT_TOKEN || '',
    site: 'datadoghq.com',
    forwardErrorsToLogs: false,
    sampleRate: 100,
  });

  const crestaVisitorId = getVisitorId();
  const navigator = window.navigator as NavigatorExperimental;
  let experimentalData = {};
  if (navigator.connection) {
    experimentalData = {
      networkType: navigator.connection.effectiveType || 'unknown',
      latestDownlinkSpeedmbps: navigator.connection.downlink || 'unknown',
    };
  }
  datadogLogs.setLoggerGlobalContext({
    service: 'hermes',
    domain:
      window && window.location && window.location.host
        ? window.location.host
        : 'unknown',
    version: process.env.REACT_APP_GIT_HASH || 'unknown',
    memoryGBs: navigator.deviceMemory || 'unknown',
    logicalProcessors: navigator.hardwareConcurrency || 'unknown',
    crestaVisitorId,
    ...experimentalData,
  });

  if (
    (process.env.DATADOG_LOG_TO_CONSOLE === '1' ||
      window.location.hostname === 'localhost') &&
    localStorage.getItem('disableDDLogToConsole') !== 'true'
  ) {
    datadogLogs.logger.setHandler(HandlerType.console);
  }
  // console is to replace existing console log later
  datadogLogs.createLogger('console', { handler: HandlerType.console });
}
